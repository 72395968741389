@import "src/scss/module";

.ServiceCard {
  --bs-card-border-radius: 0;
  --bs-card-border-width: 0;
  height: 100%;

  :global {
    .card-body {
      display: flex;
      flex-direction: column;
    }

    .card-text {
      flex-grow: 1;
    }

    .card-footer {
      display: flex;
      justify-content: flex-end;
      color: #{$green};
      font-weight: #{$font-weight-bold};
      background-color: transparent;
    }
  }
}
